<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>gewünschtes Pensum</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="">
              <v-range-slider
                v-model="range"
                :max="max"
                :min="min"
                hide-details
                class="align-center"
                thumb-label="always"
                :thumb-size="24"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="range[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(range, 0, $event)"
                  />
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="range[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(range, 1, $event)"
                  />
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12">
      <base-card>
        <v-card-title>effektives Pensum: 30</v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="courses"
            item-key="title"
            class="elevation-1 table-one"
            multi-sort
            hide-default-footer
          >
            <template v-slot:item.title="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <v-icon
                    :class="[item.iconClass]"
                    v-text="item.icon"
                  />
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.title }}
                </p>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Sliders',
    },
    data () {
      return {
        min: 0,
        max: 40,
        slider: 40,
        range: [-20, 70],
        media: 0,
        alarm: 0,
        zoom: 0,
        value: 10,
        value2: [20, 40],
        fruits: 0,
        ticksLabels: ['Figs', 'Lemon', 'Pear', 'Apple'],
        ex1: { label: 'color', val: 25, color: 'orange darken-3' },
        ex2: { label: 'track-color', val: 75, color: 'green lighten-1' },
        ex3: { label: 'thumb-color', val: 50, color: 'red' },
        rules: [(v) => v <= 40 || 'Only 40 in stock'],
        bpm: 40,
        interval: null,
        isPlaying: false,
        courses: [
          {
            icon: 'mdi-folder',
            iconClass: 'grey lighten-1 white--text',
            formation: 'KV Dienstleistung & Administration',
            course: 'Administration & Handel',
            workload: 5,
          },
          {
            icon: 'mdi-folder',
            iconClass: 'blue lighten-1 white--text',
            formation: 'KV Dienstleistung & Administration',
            course: 'Administration',
            workload: 10,
          },
          {
            icon: 'mdi-folder',
            iconClass: 'green lighten-1 white--text',
            formation: 'KV Dienstleistung & Administration',
            course: 'Wirtschaft und Gesellschaft',
            workload: 15,
          },
        ],
        search: '',
        selected: [],
        headers: [
          {
            text: 'Klass',
            align: 'start',
            sortable: false,
            value: 'course',
          },
          { text: 'Ausbildung', value: 'formation' },
          { text: 'Pensum', value: 'workload' },
        ],
      }
    },
    methods: {
      zoomOut () {
        this.zoom = this.zoom - 10 || 0
      },
      zoomIn () {
        this.zoom = this.zoom + 10 || 100
      },
    },
  }
</script>
